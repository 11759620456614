<template>
    <div class="container" id="LookSeasonRecheckDetail">
        <el-page-header @back="$router.back()" content="查看复审资料"></el-page-header>
        <div class="LookSeasonRecheckDetail_content">
            <el-descriptions title="复审信息" :column="1" :labelStyle="labelStyle">
                <el-descriptions-item label="申请机构">{{CompanyName}}</el-descriptions-item>
                <el-descriptions-item label="赛季名称">{{Season.SeasonName}}</el-descriptions-item>
                <el-descriptions-item label="赛季赛事名称">{{Season.LeagueName}}</el-descriptions-item>
                <el-descriptions-item label="赛事名称">{{League.Name}}</el-descriptions-item>
                <el-descriptions-item label="比赛项目">{{League.EventsName}}</el-descriptions-item>
                <el-descriptions-item label="比赛形式">{{League.IsOnline ? '线上赛' : '线下赛'}}</el-descriptions-item>
                <el-descriptions-item label="赛事logo">
                    <img :src="League.LogoUrl" style="height:100px;" v-if="League.LogoUrl"/>
                    <span v-else>无</span>
                </el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
            <el-descriptions title="认证指标信息" :column="1" :labelStyle="labelStyle">
                <el-descriptions-item label="最终定级" v-if="finallyLevel">
                    <el-tag
                        v-for="item in grade"
                        :key="item"
                        type="success"
                        :effect="item == finallyLevel?'dark':'plain'"
                        style="margin-right:10px"
                        size="small">
                        {{item}}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="初审等级">
                    <el-tag
                        v-for="item in grade"
                        :key="item"
                        type="danger"
                        :effect="item == tentativeLevel?'dark':'plain'"
                        style="margin-right:10px"
                        size="small">
                        {{item}}
                    </el-tag>
                </el-descriptions-item>
                <template v-for="(item, index) in TentativeIndicators">
                    <el-descriptions-item :label="item.name" :key="index" v-if="!!item.level">
                        <el-tag
                            v-for="gradeItem in grade"
                            :key="gradeItem"
                            :effect="gradeItem==item.level?'dark':'plain'"
                            style="margin-right:10px"
                            size="small">
                            {{gradeItem}}
                        </el-tag>
                    </el-descriptions-item>
                </template>
                <template v-if="hostLimit && hostLimit.form!='none'">
                    <el-descriptions-item label="主办单位">
                        <div style="width: 100%;max-width: 700px;display:flex;flex-wrap: wrap;">
                            <el-tag v-for="(item,index) in hostList" :key="index"
                                :closable="false"
                                disable-transitions effect="plain" size="small"
                                style="margin-right:10px;margin-bottom:5px"
                            >
                                {{item.name}}（{{hostLimit.levels[item.level].desc}}）
                            </el-tag>
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-if="scopeLimit && scopeLimit.form!='none'">
                    <el-descriptions-item label="参赛队伍范围">{{scopeLimit.levels[scope].desc}}</el-descriptions-item>
                    <el-descriptions-item label="举办地点">
                        <div style="width: 100%;max-width: 700px;display:flex;flex-wrap: wrap;">
                            <el-tag
                                v-for="(item,index) in addressList" :key="index"
                                :closable="false"
                                disable-transitions effect="plain" size="small"
                                style="margin-right:10px;margin-bottom:5px" 
                            >
                                {{item}}
                            </el-tag>
                        </div>
                    </el-descriptions-item>
                </template>
                <template v-if="scaleLimit && scaleLimit.form!='none'">
                    <el-descriptions-item v-for="(item,index) in scaleLimit.items" :key="index" :label="item.name">
                        {{scale[index]}}
                    </el-descriptions-item>
                </template>
                <template v-if="siteLimit && siteLimit.form!='none'">
                    <el-descriptions-item v-for="(item,index) in siteLimit.items" :key="index" :label="item.name">
                        {{site[index]}}
                    </el-descriptions-item>
                </template>
                <template v-if="judgeLimit && judgeLimit.form!='none'">
                    <el-descriptions-item  v-for="(item,index) in judgeLimit.items" :key="index" :label="item.name">
                        {{judge[index]}}
                    </el-descriptions-item>
                </template>
                <template v-if="roundsLimit && roundsLimit.form!='none'">
                    <el-descriptions-item  v-for="(item,index) in roundsLimit.items" :key="index" :label="item.name">
                        {{rounds[index]}}
                    </el-descriptions-item>
                </template>
            </el-descriptions>
            <el-divider></el-divider>
            <el-descriptions title="证明资料附件" :column="1" :labelStyle="labelStyle">
                <template v-for="(item,index) in attachmentLimit" >
                    <el-descriptions-item :label="item.name" :key="index" v-if="item.id == 2 ? false : (item.id == 3 ? HasFields : true)">
                        <div class="file" v-if="attachments[index].files && attachments[index].files.length > 0">
                            <ul>
                                <li v-for="(fileItem,fileIndex) in attachments[index].files" :key="fileIndex">
                                    <a :href="fileItem.store_path" title="点击下载">
                                        <i class="el-icon-document"></i>
                                        <span>{{fileItem.original_name}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div v-else>无</div>
                    </el-descriptions-item>
                </template>
            </el-descriptions>
            <el-divider></el-divider>
            <el-descriptions title="其他信息" :column="1" :labelStyle="labelStyle">
                <el-descriptions-item label="联系人">{{salutation || '无'}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{contactWay || '无'}}</el-descriptions-item>
                <el-descriptions-item label="赛事名称在赛事证书上的显示格式" :labelStyle="{'width':'250px'}"></el-descriptions-item>
                <el-descriptions-item label="第一行" v-if="leagueNameByLines[0]">{{leagueNameByLines[0]}}</el-descriptions-item>
                <el-descriptions-item label="第二行" v-if="leagueNameByLines[1]">{{leagueNameByLines[1]}}</el-descriptions-item>
                <el-descriptions-item label="第三行" v-if="leagueNameByLines[2]">{{leagueNameByLines[2]}}</el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
        </div>
    </div>
</template>
<script>
import {
    EditSeasonReviewApply
} from '@/api'
export default {
    data(){
        var seasonId = parseInt(this.$route.params.seasonId);
        return {
            seasonId: isNaN(seasonId) ? 0 : seasonId,
            labelStyle: {'width':'100px','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'},
            hostList:[],
            scope:"",
            addressList:[],
            scale:[],
            site:[],
            judge:[],
            rounds:[],
            attachments:[],
            leagueNameByLines:['','',''],
            salutation:'',//联系人
            contactWay:'',//联系电话
            CompanyName:"",//申请机构
            Season:{},//赛季信息
            tentativeLevel:'',//初审级别
            TentativeIndicators:[],
            finallyLevel:'',//复审级别
            grade:['A','B','C','D','E'],
            League:{},//基础信息
            AuditStatus:{},//审核状态
            HasFields:true,//是否包含场地条件
            Indicators:[],//指标数据
            hostLimit:null,
            scopeLimit:null,
            scaleLimit:null,
            siteLimit:null,
            judgeLimit:null,
            roundsLimit:null,
            attachmentLimit:null,
        }
    },
    created(){
        EditSeasonReviewApply({
            SeasonId: this.seasonId,
        }).then(data=>{
            if(data.Code == 0){
                this.League = data.League;
                var IndicatorsLimit = data.EventsCertificateDefine.Indicators;
                this.TentativeIndicators = data.ReviewData.TentativeIndicators;
                this.Indicators = IndicatorsLimit;
                this.hostLimit = IndicatorsLimit[0];
                this.scopeLimit = IndicatorsLimit[1];
                this.scaleLimit = IndicatorsLimit[2];
                this.siteLimit = IndicatorsLimit[3];
                this.judgeLimit = IndicatorsLimit[4];
                this.roundsLimit = IndicatorsLimit[5];
                this.attachmentLimit = data.EventsCertificateDefine.AttachmentKinds;
                this.HasFields = data.EventsCertificateDefine.HasFields;

                let SeasonData = data.ReviewData;
                this.Season = SeasonData.Season;
                this.tentativeLevel = SeasonData.TentativeLevel;
                this.finallyLevel = SeasonData.ApplyData.FinallyLevel;
                this.AuditStatus = SeasonData.AuditStatus;
                this.salutation = SeasonData.ApplyData.Contacts.Salutation;
                this.contactWay = SeasonData.ApplyData.Contacts.ContactWay;

                let IndicatorsData = SeasonData.ApplyData.Indicators,
                    AttachmentsData = SeasonData.ApplyData.Attachments;
                let {
                        levels: hostLevels = [],
                        values: hostValues = []
                    } = IndicatorsData[0],
                    scopeData = IndicatorsData[1],
                    scaleData = IndicatorsData[2],
                    siteData = IndicatorsData[3],
                    judgeData = IndicatorsData[4],
                    roundsData = IndicatorsData[5];
                    hostLevels = hostLevels;
                    hostValues = hostValues;
                //主办单位
                if(IndicatorsLimit[0] && IndicatorsLimit[0].form != 'none'){
                    let hostList = [];
                    for(var i=0;i<hostLevels.length;i++){
                        hostList.push({
                            level: hostLevels[i],
                            name: hostValues[i]
                        })
                    }
                    this.hostList = hostList;
                }
                //覆盖范围
                if(IndicatorsLimit[1] && IndicatorsLimit[1].form != 'none'){
                    //参赛队伍范围
                    this.scope = scopeData.level;
                    //举办地点
                    this.addressList = scopeData.value.split(',');
                }
                //参赛规模
                if(IndicatorsLimit[2] && IndicatorsLimit[2].form != 'none'){
                    this.scale = scaleData.values || [];
                }
                //场地条件
                if(IndicatorsLimit[3] && IndicatorsLimit[3].form != 'none'){
                    this.site = siteData.values || [];
                }
                //裁判配置
                if(IndicatorsLimit[4] && IndicatorsLimit[4].form != 'none'){
                    this.judge = judgeData.values || [];
                }
                //比赛副数
                if(IndicatorsLimit[5] && IndicatorsLimit[5].form != 'none'){
                    this.rounds = roundsData.values || [];
                }
                //文件数据
                this.attachments = AttachmentsData || [];
                this.Indicators = IndicatorsData;
                this.CompanyName=data.CompanyName;
                let LeagueNameByLinesData = SeasonData.ApplyData.LeagueNameByLines ? SeasonData.ApplyData.LeagueNameByLines.split(',') : null;
                if(LeagueNameByLinesData){
                    let leagueNameByLines = ['','',''];
                        leagueNameByLines.splice(0,LeagueNameByLinesData.length,...LeagueNameByLinesData);
                    this.leagueNameByLines = leagueNameByLines;
                }else{
                    this.leagueNameByLines = [data.League.Name,'',''];
                }
            }
        })
    },
    methods: {
    }
}
</script>
<style scoped>
.file {
    width: 100%;
    max-width: 500px;
}
.file ul {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}
.file ul li{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    padding: 0 3px;
    box-sizing: border-box;
    line-height: 26px;
}
.file ul li a{
    width: calc(100% - 25px);
    color: #606266;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.file ul li a i {
    margin-right: 5px;
}
.file ul li a span{
    width: 100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.file ul li:hover{
    background: #ecf5ff;
}
.file ul li:hover a span{
    color: #79bbff;
}
</style>